import './App.css';
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import { Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { createTheme } from '@mui/material/styles';
import TextTab from './components/textTab/textTab';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};


function GetInterestRatio(yearlyRaise, yearlyInterest) {
  const result = (1 +  yearlyRaise/100) / (1 + yearlyInterest/100)
  return result
}

function calculateCoumpoundInterest(principal, rateOfInterest, years){
  const result = principal * (Math.pow( 1+ rateOfInterest , years))
  return result
}

// function geometricProgression(firstTerm, commonRatio, numberOfTerms) {
//   const result = firstTerm * ( Math.pow(commonRatio,numberOfTerms) - 1) / (commonRatio - 1)
//   return result
// }

// function checkValue(value) {
//   if (value < 0) {
//     return false
//   } 

//   return true
// }

function format(amount) {
  return (amount / 100000).toFixed(2)
}

function formatK(amount) {
  return (amount / 1000).toFixed(2)
}

function formatN(amount) {
  if (amount === undefined) {
    return NaN
  }
  return amount.toFixed(2)
}
function App() {
  const [monthlyIncome, setIncome] = useState(20000);
  const [yearlyRaise, setRaise] = useState(0);
  const [yearlyInvestmentRate, setInterest] = useState(0);
  const [savings, setSavings] = useState(20);
  const [yearsTillMaturity, setyearsTillMaturity] = useState(20);
  const [result, setResult] = useState();
  const [investedAmount, setInvestedAmount] = useState();
  const [inflationRate, setInflationRate] = useState(6);
  const [inflationAdjustedValue, setinflationAdjustedValue] = useState();
  const [currentinflationAdjustedValue, setCurrentinflationAdjustedValue] = useState();
  const [futureMonthlySalary, setfutureMonthlySalary] = useState();
  const [ iavWealth, setIAVWealth] = useState();
  const [yearlyInvestmentAmount, setYearlyInvestmentAmount] = useState();

  function GetInvestmentValue(yearlyInvestmentAmount){
    const ratio = GetInterestRatio(yearlyRaise,yearlyInvestmentRate)
    const firstYearInvestment = yearlyInvestmentAmount * Math.pow( 1 + yearlyInvestmentRate/100 , yearsTillMaturity  )
    var result
    if (ratio === 1) {
      result = firstYearInvestment*yearsTillMaturity
    } else {
      result = firstYearInvestment * (Math.pow(ratio, yearsTillMaturity) - 1) / ( ratio - 1)
    }
    const IAV_wealth = calculateCoumpoundInterest(1,yearlyInvestmentRate/100, yearsTillMaturity)
    return result
  }

  function GetTotalInvestment(yearlyInvestmentAmount) {
    var amount
    if (yearlyRaise === 0) {
      amount = yearlyInvestmentAmount * yearsTillMaturity

    } else {
      amount = yearlyInvestmentAmount * ( Math.pow(( 1 + yearlyRaise/100), yearsTillMaturity) - 1) / (yearlyRaise/100)
    }

    return amount
  }

  function CalculateInflationAdjustedValue(inflationRate){
    const IAV = calculateCoumpoundInterest(1,inflationRate/100, yearsTillMaturity)
    console.log('IAV: %f', IAV)
    

    const IAV_wealth = calculateCoumpoundInterest(1,yearlyInvestmentRate/100, yearsTillMaturity)
    const IAVAdjusted = result * (IAV_wealth/IAV)
    return IAVAdjusted
  }
  const calculate = () => {
    // A = P(1 + (r/n))^(nt)
    // 
    // const result = (monthlyIncome*12*savings/100) * ( Math.pow(ratio,yearsTillMaturity) - 1) / (ratio - 1)
    const yearlyInvestmentAmount = monthlyIncome * savings / 100 * 12
    setYearlyInvestmentAmount(yearlyInvestmentAmount)
    console.log(yearlyInvestmentAmount)
    const ratio = GetInterestRatio(yearlyRaise,yearlyInvestmentRate)
    console.log('ratio: %f',ratio)
    const firstYearInvestment = yearlyInvestmentAmount * Math.pow( 1 + yearlyInvestmentRate/100 , yearsTillMaturity  )
    console.log(firstYearInvestment)
    var result
    if (ratio === 1) {
      result = firstYearInvestment*yearsTillMaturity
    } else {
      result = firstYearInvestment * (Math.pow(ratio, yearsTillMaturity) - 1) / ( ratio - 1)
    }
    console.log(result)
    var amount
    if (yearlyRaise === 0) {
      amount = yearlyInvestmentAmount * yearsTillMaturity

    } else {
      amount = yearlyInvestmentAmount * ( Math.pow(( 1 + yearlyRaise/100), yearsTillMaturity) - 1) / (yearlyRaise/100)
    }
    
    
    const IAV_wealth = calculateCoumpoundInterest(1,yearlyInvestmentRate/100, yearsTillMaturity)
    console.log('wealth: %f', IAV_wealth)
    const IAV = calculateCoumpoundInterest(1,inflationRate/100, yearsTillMaturity)
    console.log('IAV: %f', IAV)
    
    const IAV_wealth_month = calculateCoumpoundInterest(monthlyIncome*12,yearlyRaise/100, yearsTillMaturity) / (monthlyIncome * 12)
    
      const IAVAdjusted = result * (IAV_wealth/IAV)
      console.log('IAV Adjusted: %f', IAVAdjusted)

    setfutureMonthlySalary(calculateCoumpoundInterest(monthlyIncome,yearlyRaise/100, yearsTillMaturity))
    setCurrentinflationAdjustedValue( (monthlyIncome*(IAV_wealth_month/IAV)))
    setResult(result)
    setInvestedAmount(amount)
    setinflationAdjustedValue(IAVAdjusted)

    let element = document.getElementById("results-spec");
    element.removeAttribute("hidden");
    element.style.visibility = "visible"
    element.style.height = "100%";

    let element2 = document.getElementById("form-data");
    // element2.setAttribute("hidden", true);
    element2.style.visibility = "hidden"
    element2.style.height = 0
  }

  function futureValue(value) {
    const result = calculateCoumpoundInterest(value,inflationRate/100, yearsTillMaturity)

    console.log('future value: %f' , result)
    return result
  }

  function clear(){
    let element = document.getElementById("results-spec");
    element.style.visibility = "hidden"
    element.style.height = 0;
    
    let element2 = document.getElementById("form-data");
    element2.style.visibility = "visible"
    // element2.removeAttribute("hidden");
    element2.style.height = "100%";
  }
  return (
    <>
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div  className="flex-grid">
        <div id="form-data">


      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
          <InputLabel htmlFor="monthly-income">Current Monthly Income</InputLabel>
          <FilledInput
            id="monthly-income"
            onChange = {(e) => setIncome(e.target.value)}
            required = {true}
            defaultValue={20000}
            startAdornment={<InputAdornment position="start">&#x20B9;</InputAdornment>}
          />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <Typography htmlFor="raise">Estimated Average Raise or Increment in % (Percent) per year | {yearlyRaise}% </Typography>
        <Slider 
          defaultValue={0} 
          step={1} 
           
          min={0} 
          max={100} 
          onChange= {(e) => setRaise(e.target.value)}
          aria-label="Average Raise or Increment in %"
        />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <Typography htmlFor="savings">Estimated Average Interest Rate of Investment (Percent) per year | {yearlyInvestmentRate}% </Typography>
        <Slider 
          defaultValue={0} 
          step={1} 
           
          min={0} 
          max={100} 
          onChange= {(e) => setInterest(e.target.value)}
          aria-label="Expected savings (Percent)"
        />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <Typography htmlFor="savings">Expected savings in percent (%) of monthly income | {savings}% or {savings * monthlyIncome / 100} Monthly </Typography>
        <Slider 
          defaultValue={20} 
          step={1} 
           
          min={0} 
          max={100} 
          onChange= {(e) => setSavings(e.target.value)}
          aria-label="Expected savings (Percent)"
        />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <Typography htmlFor="current-yearsTillMaturity">Years till retirement | {yearsTillMaturity} years</Typography>
        <Slider 
        defaultValue={20} 
        step={1} 
         
        min={1} 
        max={65} 
        onChange= {(e) => setyearsTillMaturity(e.target.value)}
        aria-label="Years till Retirement"
        />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <Typography htmlFor="current-yearsTillMaturity">Average Inflation Rate in percent (%)| {inflationRate}% </Typography>
        <Slider 
        defaultValue={6} 
        step={1} 
         
        min={1} 
        max={20} 
        onChange= {(e) => setInflationRate(e.target.value)}
        aria-label="Inflation Rate"
        />
      </FormControl>
      <Button
            variant="contained"
            color="primary"
            onClick={() => {
              calculate();
            }}
          >
            Calculate
          </Button>
          </div>
        <div id="results-spec" hidden>
          <div className='spec'>
            <ol>
            <li>
              You would be investing - &#x20B9;{yearlyInvestmentAmount} for the first year (&#x20B9;{format(yearlyInvestmentAmount)} Lakh) or &#x20B9;{yearlyInvestmentAmount / 12} monthly
            </li>
            <li>
            Total Wealth by Retirement - &#x20B9;{format(GetInvestmentValue(yearlyInvestmentAmount))} Lakh
            </li>
            <li>
            Amount Invested by Retirement - &#x20B9;{format(GetTotalInvestment(yearlyInvestmentAmount))} Lakh
            </li>
            <li>
            Value as of This year (Inflation Adjusted Value) - &#x20B9;{format(CalculateInflationAdjustedValue(inflationRate))} Lakh
            </li>
            <li> Price Action Change
              <ul>
                <li>If a phone costs 10k today, it would cost {formatK(futureValue(10000))}k after {yearsTillMaturity} years.</li>

                <li>You can currently buy {Math.floor(yearlyInvestmentAmount/10000)} phones yearly. </li>

                <li>After {yearsTillMaturity} years, if you save and invest you can buy {Math.floor(result / futureValue(10000))} phones.</li>

                <li>After {yearsTillMaturity} years, if you just save and don't invest anywhere you can buy {Math.floor(investedAmount / futureValue(10000))} phones.</li>

                <li>But if you don't save and invest, with your regular increment you can buy only {Math.floor(futureMonthlySalary*12*savings/100 / futureValue(10000))} phones.</li>

              </ul>
            </li>
            <li>
            Your monthly salary after {yearsTillMaturity} years would be &#x20B9;{formatN(futureMonthlySalary)} (&#x20B9;{formatN(futureMonthlySalary*12)} yearly) which is of value &#x20B9;{formatN(currentinflationAdjustedValue)} in today's date
            </li>
            </ol>
          </div>
          <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clear();
            }}
          >
            Recalculate
          </Button>
          </div>
        </div>

      </div>
      </Box>
    </>
  );
}

export default App;
